.spinner {
  margin-right: 16px;
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid;
  border-right: 3px solid;
  width: 36px;
  height: 36px;
  animation: spin 0.5s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
