.accordion-header {
  &.light {
    .szh-accordion__item-heading {
      button {
        background-color: #fff;
        // box-shadow: 0 0 12px 1px #ccc;
        border: 1px solid #ddd;
        color: #000;
      }
    }
  }
  &.dark {
    .szh-accordion__item-heading {
      button {
        background-color: #293745;
      }
    }
  }

  .szh-accordion__item-heading {
    button {
      width: 100%;
      border: none;
      text-align: left;
      font-size: 16px;
      border-radius: 5px;
      padding: 8px 16px;
      transition: 0.1s all ease-in;
      cursor: pointer;
      &:hover {
        box-shadow: inset 0 0 12px -4px;
      }
    }
  }
}
